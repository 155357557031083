import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = import.meta.globEager('./locales/*.json');
  const messages = {};

  for (const key in locales) {
    const matched = key.match(/\/locales\/([A-Za-z0-9-_]+)\.json/);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[key];
    }
  }

  return messages;
}

const i18n = createI18n({
  locale: window.App.language,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
});

export default i18n;
