<template>
  <div class="language-switcher" :class="{ 'language-switcher--floating': floating }" v-click-outside="close">
    <div class="language-switcher__dropdown" v-show="isOpen">
      <a
        v-for="language in languages"
        :key="language.code"
        :class="{ 'language-switcher__item--active': language.code === currentLanguage }"
        class="language-switcher__item"
        @click="handleUrl(language.code)">
        <img :src="language.flag" alt="flag" v-if="language.flag" />
        {{ language.name }}
      </a>
    </div>

    <div class="language-switcher__button" @click="isOpen = !isOpen">
      <template v-if="isMobile">
        <img src="https://images.iphonephotographyschool.com/35931/Frame-503.svg" alt="globe" />
        <img src="https://images.iphonephotographyschool.com/35932/Vector-1.svg" alt="chevron" />
      </template>
      <template v-else>
        <img src="https://images.iphonephotographyschool.com/35933/Frame-503.svg" alt="globe" />
        <img src="https://images.iphonephotographyschool.com/35930/Vector-1.svg" alt="chevron" />
      </template>
    </div>
  </div>
</template>

<script>
import clickOutside from '@shared/directives/clickOutside.js';
import languageNames from '../../locales/constants/names.js';
import flags from '../../locales/constants/flags.js';

export default {
  props: {
    floating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  directives: {
    'click-outside': clickOutside,
  },
  computed: {
    languages() {
      const userLanguage = window.navigator.language || window.navigator.userLanguage;
      const userLanguageCode = userLanguage.toLowerCase();
      const locales = import.meta.globEager('./../../locales/*.json');
      const languages = [];

      for (const key in locales) {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
          const locale = matched[1];
          const flagMatch = userLanguageCode.includes(locale);

          languages.push({
            code: locale,
            name: languageNames[locale],
            url: this.generateURL(locale),
            flag: flagMatch ? flags[userLanguageCode] || flags[locale] : flags[locale] || flags[locale],
          });
        }
      }

      return languages;
    },
  },
  methods: {
    generateURL(language) {
      const url = new URL(window.location.href);

      url.searchParams.set('lang', language);

      if (!url.searchParams.get('switch-lang')) {
        url.href += '&switch-lang=1';
      }

      return url.href;
    },
    handleUrl(language) {
      window.location.href = this.generateURL(language);
    },
    close() {
      if (!this.isOpen) return;

      this.$nextTick(() => {
        this.isOpen = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.language-switcher {
  position: relative;

  @include phone-landscape {
    margin: 0 12px 0 0;
  }

  &--floating {
    position: absolute;
    bottom: 16px;
    right: 17px;
    margin: 0;
    border-radius: 12px;
    border: 1px solid var(--gray-6);
    background: var(--gray-8);
    z-index: 8;

    @include tablet-portrait(portrait) {
      bottom: 32px;
      right: 32px;
    }

    @include tablet-landscape {
      bottom: 32px;
      right: 32px;
    }

    .language-switcher__dropdown {
      top: auto;
      bottom: calc(100% + 20px);
    }

    .language-switcher__button {
      height: 42px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 36px;
    padding: 0 12px;
    cursor: pointer;

    span {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 6px);
    right: 0;
    padding: 3px;
    border-radius: 8px;
    border: 1px solid var(--gray-6);
    background: var(--gray-8);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    min-width: 85px;
    font-size: 13px;
    line-height: 30px;
    color: var(--gray-1);
    padding: 0 8px;
    border-radius: 4px;

    &--active {
      color: #fff;
      background: #4498f7;

      &:hover {
        text-decoration: none;
      }
    }

    img {
      min-width: 18px;
      max-width: 18px;
    }
  }
}
</style>
